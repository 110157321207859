<template>
    <div class="finish-wrap">
        <div class="logo">
            <img src="../assets/images/bentley_logo_a-c1@2x.png"  alt="" />
        </div>
        <div class="info-box">
            <span>{{toTraditional(courseName)}}</span>
        </div>
        <div class="course-box">
            <div class="item-row">
                <div class="item-box">
                    <div class="row1">
                        <span class="span1">您的預約信息</span>
                        <div :class="['btn', {'disabled': !btnstatus()}]" @click="btnstatus() && handleEnrollmentCancel()">取消報名</div>
                    </div>
                    <div class="row2">
                        <span class="span2">{{(courseDetail.classAddress) ? '上課地點：' : '課程方式：'}}</span>
                        <span class="span3">{{(courseDetail.classAddress) ? courseDetail.classAddress : '虛擬課堂'}}</span>
                    </div>
                    <div class="row2">
                        <span class="span2">上課時間：</span>
                        <span class="span3">{{ classDateText()}}</span>
                    </div>
                    <div class="row2" v-if="courseDetail.showClass70607">
                        <span class="span2"></span>
                        <span class="span3">2022年6月7日 9:00-10:30</span>
                    </div>
                    <div class="row2" v-if="courseDetail.showClass70609">
                        <span class="span2"></span>
                        <span class="span3">2022年6月9日 9:00-10:30</span>
                    </div>
                    <div class="row2" v-if="courseDetail.showClass70614">
                        <span class="span2"></span>
                        <span class="span3">2022年6月14日 9:00-10:30</span>
                    </div>
                    <div class="row2" v-if="courseDetail.showClass70616">
                        <span class="span2"></span>
                        <span class="span3">2022年6月16日 9:00-10:30</span>
                    </div>
                    <div class="row2" v-if="courseDetail.showClass71207">
                        <span class="span2"></span>
                        <span class="span3">2022年12月8日 9:00-10:30</span>
                    </div>
                    <div class="row2" v-if="courseDetail.classUrl">
                        <span class="span2">課程鏈接：</span>
                        <span class="span4" @click="copyUrl()">點擊複製</span>
                    </div>
                </div>
            </div>
            <div class="avatar">
                <img v-if="courseImageHkUrl" :src="courseImageHkUrl" class="img">
            </div>
        </div>
    </div>
</template>
<script>
import { Landing } from '@/network'
import { Dialog, Icon } from 'vant';
import moment from 'moment';
import { toTraditional } from '@/utils'

export default {
    data() {
        return {
            hubId: sessionStorage.getItem('hubId') || null,
            phone: sessionStorage.getItem('phone') || null,
            courseId: null,
            courseName: null,
            enrollmentDetail: {},
            courseDetail: {},
            courseImageHkUrl: '',
            toTraditional
        }
    },
    components: {
        VanIcon: Icon,
    },
    async created() {
        this.courseId = this.$route.params.courseId
        // this.uesrInfo = await Landing.getUserInfo({phone: this.phone})
        const course = await Landing.getCourse({id: this.courseId})
        if (course) {
            this.courseName = course.courseName
            this.courseImageHkUrl = course.courseImageHkUrl
        }
        this.enrollmentDetail = await Landing.getEnrollmentDetail({phone: this.phone, courseId: this.courseId})
        if (this.enrollmentDetail) {
            this.courseDetail = await Landing.getCourseDetail({id: this.enrollmentDetail.courseDetailId})
        }
        if (course.courseNo == '销售课程7') {
            this.courseDetail.showClass70607 = false
            this.courseDetail.showClass70609 = false
            this.courseDetail.showClass70614 = false
            this.courseDetail.showClass70616 = false
            this.courseDetail.showClass71207 = false
            if (moment(this.courseDetail.classStartDate).format('YYYY年M月D日') == '2022年6月6日') {
                this.courseDetail.showClass70607 = true
            }
            if (moment(this.courseDetail.classStartDate).format('YYYY年M月D日') == '2022年6月8日') {
                this.courseDetail.showClass70609 = true
            }
            if (moment(this.courseDetail.classStartDate).format('YYYY年M月D日') == '2022年6月13日') {
                this.courseDetail.showClass70614 = true
            }
            if (moment(this.courseDetail.classStartDate).format('YYYY年M月D日') == '2022年6月15日') {
                this.courseDetail.showClass70616 = true
            }
            if (moment(this.courseDetail.classStartDate).format('YYYY年M月D日') == '2022年12月7日') {
                this.courseDetail.showClass71207 = true
            }
        }
        // console.warn('xxxxxxxxxxx courseNo = ', course.courseNo)
        // console.warn('xxxxxxxxxxx showClass70607 = ', this.courseDetail.showClass70607)
        // console.warn('xxxxxxxxxxx showClass70609 = ', this.courseDetail.showClass70609)
        // console.warn('xxxxxxxxxxx showClass70614 = ', this.courseDetail.showClass70614)
    },
    methods: {
        btnstatus(item) {
            return true
        },
        classDateText() {
            //if (this.courseDetail && this.courseDetail.classStartDate && this.courseDetail.classEndDate)
            //    return moment(this.courseDetail.classStartDate).format('YYYY年M月D日 H:mm') + '-' + moment(this.courseDetail.classEndDate).format('H:mm')
            if (this.courseDetail && this.courseDetail.classStartDate && this.courseDetail.classEndDate){
                console.log(moment(this.courseDetail.classStartDate).format('YYYY年M月D日')+' _'+ moment(this.courseDetail.classEndDate).format('YYYY年M月D日')+'')
                if(moment(this.courseDetail.classStartDate).format('YYYY年M月D日')+'' == moment(this.courseDetail.classEndDate).format('YYYY年M月D日')+'') {
                    return moment(this.courseDetail.classStartDate).format('YYYY年M月D日 H:mm') + '-' + moment(this.courseDetail.classEndDate).format(' H:mm')
                }else{
                    return moment(this.courseDetail.classStartDate).format('YYYY年M月D日 H:mm') + '-\n' + moment(this.courseDetail.classEndDate).format('YYYY年M月D日 H:mm')
                }
            }
        },

        
        getClassDate(classDate) {
            return moment(classDate).format('M月D日 H:mm') 
        },
        getWeek(classDate) {
            let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
            let wk = new Date(moment(classDate).format('YYYY/MM/DD')).getDay()
            return weeks[wk]
        },
        getDaysBetween(courseDetail) {
          var startDate = new Date(moment(courseDetail.classStartDate).format('YYYY/MM/DD'))
          var endDate = new Date(moment(courseDetail.classEndDate).format('YYYY/MM/DD'))
          var days=(endDate - startDate)/(1*24*60*60*1000) + 1;
          return  days;
        },
        handleEnrollmentCancel() {
            Dialog.confirm({
                width: '80%',
                allowHtml: true,
                className: 'dialog-alert-font-class-course-detail',
                messageAlign: 'center',
                title: '取消報名',
                message: '確定取消報名此課程?'
            }).then(() => {
                var endDate = new Date(moment(this.courseDetail.appointmentEndDate).format('YYYY/MM/DD'))
                var hours = (new Date() - endDate) / (60*60*1000);
                if (hours > 24) {
                    Dialog.alert({
                        width: '95%',
                        allowHtml: true,
                        className: 'dialog-alert-font-class2',
                        messageAlign: 'left',
                        title: '非常抱歉',
                        message: '本次課程已截止報名，您不能取消該報名。如有任何問題，請您與BMC培訓助理進行聯繫：<br>BMC_trainingassistant@kornferry.com'
                    })
                } else {
                    // this.courseDetail = 
                    Landing.getCourseDetail({id: this.enrollmentDetail.courseDetailId}).then((data) => {
                        this.courseDetail = data
                        Landing.cancelEnrollment({phone: this.phone, courseDetailId: this.courseDetail.id, version: this.courseDetail.version}).then(() => {
                            Dialog.alert({
                                width: '80%',
                                allowHtml: true,
                                className: 'dialog-alert-font-class-course-detail',
                                messageAlign: 'center',
                                title: '取消成功',
                                message: '您已成功取消報名'
                            })
                            this.$router.replace({name: 'Enrollment'})
                        })
                    })
                }
            }).catch(() => {
            });
        },
        isAppointmentFinish(courseDetail) {
            let status = true
            if (courseDetail) {
                let endTime = new Date(moment(courseDetail.appointmentEndDate).format('YYYY/MM/DD')).getTime() + (1*24*60*60*1000)
                let current = new Date().getTime()
                if (current < endTime) {
                    status = false
                }
            }
            return status
        },
        enrollmentStatus(courseDetail) {
            return (courseDetail.limitCount <= courseDetail.realCount) || this.isAppointmentFinish(courseDetail)
        },
        copyUrl() {
            if(this.courseDetail.classUrl == '' || this.courseDetail.classUrl == 'http://') {
                Dialog.alert({
                    width: '85%',
                    allowHtml: true,
                    className: 'dialog-alert-font-class-course-detail',
                    messageAlign: 'left',
                    title: '非常抱歉',
                    message: '課程鏈接尚未發布，請等待發布之後復製鏈接。如有任何問題，請您與BMC培訓助理進行聯系：<br>BMC_trainingassistant@kornferry.com'
                })
                return
            }
            let cInput = document.createElement("input");
            cInput.value = this.courseDetail.classUrl
            document.body.appendChild(cInput);
            cInput.select();
            document.execCommand("Copy");
            document.body.removeChild(cInput);
            Dialog.alert({
                width: '90%',
                allowHtml: true,
                className: 'dialog-alert-font-class2',
                messageAlign: 'left',
                title: '鏈接複製成功',
                message: '您的課程鏈接複製成功，請打開瀏覽器並粘貼到地址欄。推薦安裝Microsoft Teams App，並使用平板或者電腦以獲得更佳課程體驗。'
            })
        }
    }
}
</script>

<style lang="less">
.dialog-alert-font-class-course-detail {
  font-family: 'Bentley', 'simhei', RegularEng, Helvetica, 'Avenir', Arial, sans-serif !important;
//   font-size: 12px !important;
  @dialog-width: 400px;
  @dialog-font-size: @font-size-sm;
}
</style>

<style lang="scss" scoped>
.finish-wrap{
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.logo {
    width: 375px;
    height: 80px;
    background-color: white;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    img {
        width: 84px;
        height: 42px;
        margin: 20px 20px;
    }
}
.info-box {
    width: 100%;
    height: 105px;
    background-color: rgba(0,50,32,0.7);
    color: #fff;
    display: flex;
    align-items: center;
    // margin-bottom: 10px;
    span {
        width: 100%;
        align-content: center;
        text-align: left;
        font-size: 1.2em;
        margin: 20px;
    }
}
.course-box {
    width: 100%;
    // height: 120px;
    .item-row {
        width: auto;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 5px 10px 5px;
        .item-box {
            width: 100%;
            height: 100%;
            border: 1px solid rgba(227, 227, 227, 1);
            border-radius: 5px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .row1 {
                margin: 10px 15px 5px 15px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            .span1 {
                font-size: 0.9em;
                padding: 5px 10px 5px 0px;
                color: rgba(0,50,32,0.7);
                font-weight: bold;
            }
            .span2 {
                width: 22%;
                font-size: 0.9em;
                padding: 0px 0px 10px 15px;
            }
            .span3 {
                font-size: 0.9em;
                padding: 0px 0px 10px 0px;
            }
            .row2 {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
            }
            .row4 {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
            }
            .span4 {
                color: rgba(0,50,32,0.7);
            }
        }
        .btn {
            width: 80px;
            height: 16px;
            line-height: 16px;
            border: 1px solid rgba(0,50,32,0.7);
            text-align: center;
            padding: 5px 3px;
            color: #fff;
            background-color: rgba(0,50,32,0.7);
            border-radius: 3px;
            &.disabled {
                background: #999;
            }
        }
    }
    .avatar {
        width: auto;
        margin: 5px 2px 5px;
        display: flex;
        img {
            width: 100%;
            border-radius: 5px;
        }
    }
    .square1 {
        width: 12px;
        height: 14px;
        background-color: #d2d2d4;
        margin: 1px;
        display: flex;
        align-items: center;
        text-align: center;
    }
    .square2 {
        width: 12px;
        height: 14px;
        background-color: rgba(0,50,32,0.7);
        margin: 1px;
        display: flex;
        align-items: center;
        text-align: center;
    }
}

</style>